<template>
    <section>
        <!-- <page-loader :isloaded="isloaded"></page-loader> -->
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="limit-x">
                                <ProductZoomer
                                v-if="dataisloaded"
                            :base-images="images"
                            :base-zoomer-options="zoomerOptions"
                            />
                            </div>
                            <div class="user-avatar mb-auto">
                                <!-- <img src="@/assets/images/import/car.jpg" height="150" alt="profile image" class="profile-img">
                                <span class="edit-avatar-icon img-logo"><img src="@/assets/images/import/logo-mini.png" alt="logo"></span> -->
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <h3 class="">{{car.titre}}</h3>
                            <h2 class="orange" v-if="car.offre">{{car.offre.prix}} €</h2>
                            <p class="equipement mt-2">{{car.equipements}}</p>
                       
                        </div>
                    </div>

                    <!-- details + icon -->
                    <div class="row">
                        <div class="col-12">
                            <div class="details-wrapper pt-0">
                                <div class="feature-list pt-5">
                                    <div class="row feature-list-row text-center">
                                        <div class="col-lg-3 feature-list-item border-right">
                                            <i class="pb-0">
                                            <img src="@/assets/images/import/gearbox.png" class="icon" alt="gearbox">
                                            </i>
                                            <h3>{{car.boiteDeVitesse}}</h3>
                                        </div>
                                        <div class="col-lg-3 feature-list-item border-right">
                                            <i class="mdi mdi-gauge pb-0"></i>
                                            <h3>{{car.kilometrage}} Km</h3>
                                        </div>
                                        <div class="col-lg-3 feature-list-item border-right">
                                            <i class="mdi mdi-gas-station pb-0"></i>
                                            <h3>{{car.energie}}</h3>
                                        </div>
                                        <div class="col-lg-3 feature-list-item border-right">
                                            <i class="mdi mdi-seat-recline-normal pb-0"></i>
                                            <h3>{{car.nb_places}} Places</h3>
                                            <!-- <p class="feature-description">Wide range of Widgets are available with this Package.</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12">
                            <h3 class="text-center">Détails de la voiture</h3>
                        </div>
                    </div>
                    <div class="row mt-4 d-flex justify-content-center">
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Référence
                                </span>
                                <span class="float-right text-muted">
                                {{car.reference}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Marque
                                </span>
                                <span class="float-right text-muted">
                                {{car.marque}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Modèle
                                </span>
                                <span class="float-right text-muted">
                                {{car.modele}}
                                </span>
                            </p>
                            <p class="clearfix  py-4">
                                <span class="float-left">
                                Version
                                </span>
                                <span class="float-right text-muted">
                                {{car.version}}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Première Main
                                </span>
                                <span class="float-right text-muted">
                                {{car.premiere_main}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Catégorie
                                </span>
                                <span class="float-right text-muted">
                                {{car.categorie}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Mise en circulation
                                </span>
                                <span class="float-right text-muted">
                                {{car.mise_en_circulation}}
                                </span>
                            </p>
                            <p class="clearfix py-4">
                                <span class="float-left">
                                Carrosserie
                                </span>
                                <span class="float-right text-muted">
                                {{car.carrosserie}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- 2ème partie détails -->
                    <div class="row mt-4 d-flex justify-content-center">
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Boite de vitesse
                                </span>
                                <span class="float-right text-muted">
                                {{car.boite_de_vitesse}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Energie
                                </span>
                                <span class="float-right text-muted">
                                {{car.energie}}
                                </span>
                            </p>
                            <p class="clearfix py-4">
                                <span class="float-left">
                                kilométrage
                                </span>
                                <span class="float-right text-muted">
                                {{car.kilometrage}} km
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Nombre de places
                                </span>
                                <span class="float-right text-muted">
                                {{car.nb_places}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Nombre de portes
                                </span>
                                <span class="float-right text-muted">
                                {{car.nb_portes}}
                                </span>
                            </p>
                            <p class="clearfix  py-4">
                                <span class="float-left">
                                Couleur
                                </span>
                                <span class="float-right text-muted">
                                {{car.couleur}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- 3ème partie détails -->
                    <div class="row mt-4 d-flex justify-content-center">
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Date de saisie
                                </span>
                                <span class="float-right text-muted">
                                {{car.date_saisie}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Puissan Fiscale
                                </span>
                                <span class="float-right text-muted">
                                {{car.puissance_fiscale}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Puissance Réelle
                                </span>
                                <span class="float-right text-muted">
                                {{car.puissance_reelle}}
                                </span>
                            </p>
                            <p class="clearfix  py-4">
                                <span class="float-left">
                                Emission Co2
                                </span>
                                <span class="float-right text-muted">
                                {{car.emission_co2}}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6" v-if="car.offre">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Offre- Type
                                </span>
                                <span class="float-right text-muted">
                                {{car.offre.type}}
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-2">
                                <span class="float-left">
                                Prix
                                </span>
                                <span class="float-right text-muted">
                                {{car.offre.prix}} €
                                </span>
                            </p>
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Prix décision
                                </span>
                                <span class="float-right text-muted">
                                {{car.offre.prix_precision}}
                                </span>
                            </p>
                            <p class="clearfix py-4">
                                <span class="float-left">
                                Garantie
                                </span>
                                <span class="float-right text-muted">
                                {{car.offre.garantie}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- 4ème partie détails -->
                    <div class="row mt-4 d-flex justify-content-center">
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Contact
                                </span>
                                <span class="float-right text-muted">
                                {{car.contact_a_afficher}}
                                </span>
                            </p>
                            <p class="clearfix py-2">
                                <span class="float-left">
                                Email
                                </span>
                                <span class="float-right text-muted">
                                {{car.email_a_afficher}}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p class="clearfix border-bottom py-4">
                                <span class="float-left">
                                Téléphone
                                </span>
                                <span class="float-right text-muted">
                                {{car.telephoneAAfficher}}
                                </span>
                            </p>
                            <p class="clearfix py-2">
                                <span class="float-left">
                                Millesime
                                </span>
                                <span class="float-right text-muted">
                                {{car.millesime}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import PageLoader from '../components/pageLoader.vue';
const axios = require('axios').default
export default {
    name: 'vehiculeDetails',
    components: {
        // PageLoader
    },
    data() {
        return {
            dataisloaded: false,
            car:'',
            photos: [],
             'zoomerOptions': {
                zoomFactor: 3, // scale for zoomer
                pane: 'pane', // three type of pane ['pane', 'container-round', 'container']
                hoverDelay: 200, // how long after the zoomer take effect
                namespace: 'zoomer-bottom', // add a namespace for zoomer component, useful when on page have mutiple zoomer
                move_by_click:true, // move image by click thumb image or by mouseover
                scroll_items: 5, // thumbs for scroll
                choosed_thumb_border_color: "#bbdefb", // choosed thumb border color
                scroller_button_style: "line",
                scroller_position: "bottom",
                zoomer_pane_position: "right"
            },
            isloaded:false
        }
    },
    computed: {
        images() {
            let images = {
                thumbs: [],
                normal_size : [],
                large_size : []
            }
             for ( let x = 0; x < this.photos.length; x++) {
                images.large_size.push({id:x, url:this.photos[x]})
                images.thumbs.push({id:x, url:this.photos[x]})
                images.normal_size.push({id:x, url:this.photos[x]})
            }
            return images
        }
    },
    methods:{
        async getCardetails () {
            let reference = this.$route.params.reference
            await axios.get(`vehicules/${reference}`)
            .then(async resCar => {
                // console.log('photo dans getCardetails',resCar.data.result.photos.photo)
                this.car=resCar.data.result
                this.photos = resCar.data.result.photos.photo
                this.dataisloaded="true"
                })
                .catch (errCar => {
                    console.log(errCar)
                })
            },
    },
    created () {
        // console.log('created');
         this.getCardetails()
    },
    mounted() {
        // document.onreadystatechange = () => {
        //     if(document.readyState == "complete") {
        //         this.isloaded = true;
        //     }
        // }
    }
}
</script>

<style scoped>
.img-logo {
border: none !important;
background-color: #faebd700  !important;
right: none;
left: 2%;
bottom: 2%
}

img{
    width:100%;
    height: auto
}
.icon {
    width: auto;
    height: 43px;
}
.limit-x {
    flex: none
}

.equipement {
    max-height: 30vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #292929 #f0f0f0 ;
}
::-webkit-scrollbar-track {
    border-radius:20px
} 
.section::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
}


</style>